
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppContext, NextWebVitalsMetric } from 'next/app';
import React, { useEffect, useState } from 'react';
import '@new/styles/global.css';
import { AppPropsWithLayout } from '../types/layouts/types';
import Head from 'next/head';
import queryString from 'querystring';
import { COOKIE_NEXT_LOCALE } from '@app/constants/cookie';
import { authServiceBrowser, browserHttpApi, CURRENT_LOCALE, makeBrowserHttpClient } from '@app/api/browserHttpApi';
import { useLoginGeoState } from '@common/hooks/useLoginGeoState';
import { useRouter } from 'next/router';
type AppInitialProps = {
    currency: string;
};
function App({ Component, pageProps }: AppPropsWithLayout & AppInitialProps) {
    const [queryClient] = useState(() => new QueryClient());
    const { loginGeo } = useLoginGeoState();
    const { locale } = useRouter();
    const getLayout = Component.getLayout ?? (page => page);
    if (browserHttpApi === null) {
        makeBrowserHttpClient({
            loginGeoData: loginGeo,
            locale,
        });
    }
    return (<>
			<Head>
				<meta name='viewport' content='width=device-width, initial-scale=1.0'/>
			</Head>
			<QueryClientProvider client={queryClient}>
				{/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
				<Hydrate state={pageProps?.dehydratedState as DehydratedState}>
					{/*next12 and react 17 https://github-com.translate.goog/vercel/next.js/discussions/37498?_x_tr_sl=en&_x_tr_tl=ru&_x_tr_hl=ru&_x_tr_pto=wapp#discussioncomment-2895579*/}
					{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
					{/*@ts-ignore*/}
					{/*<AxiosInterceptor>{getLayout(<Component {...pageProps} />)}</AxiosInterceptor>*/}
					{getLayout(<Component {...pageProps}/>)}
					<ReactQueryDevtools initialIsOpen={false}/>
				</Hydrate>
			</QueryClientProvider>
		</>);
}
// TODO release remove
export function reportWebVitals(metric: NextWebVitalsMetric) {
    // eslint-disable-next-line no-console
    console.log(metric);
}
const __Next_Translate__Page__19249641ea8__ = App;
App.getInitialProps = ({ ctx }: AppContext) => {
    if (ctx.req) {
        const rawCookies = ctx.req.headers.cookie;
        const locale = ctx.locale;
        const path = ctx.asPath;
        if (rawCookies !== undefined) {
            const cookies = queryString.decode(ctx.req.headers.cookie || '', '; ');
            let targetLocale = cookies[COOKIE_NEXT_LOCALE] as string;
            if (targetLocale !== locale && targetLocale) {
                targetLocale = `/${targetLocale}`;
                ctx.res?.writeHead(302, {
                    Location: `${targetLocale}${path || ''}`,
                });
                ctx.res?.end();
            }
        }
    }
    return {};
};

    export default __appWithI18n(__Next_Translate__Page__19249641ea8__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  